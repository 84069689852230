.ClubOfficialsCard {
  margin: 20px;
  position: relative;
  min-width: 280px;
  min-height: 300px;
  padding: 20px;
  border-radius: 1rem;
  box-sizing: content-box;
  background-color: black;
  border: 2px solid transparent;
  box-shadow: 0px 10px 100px 2px rgba(46, 61, 98, 0.3);
}

.ClubOfficialsCard .front {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ClubOfficialsCard .front .imgae {
  width: 200px;
  height: 200px;
  border-radius: 100%;
}

.ClubOfficialsCard .front img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
  border: 4px solid #f9224b;
  transition: 0.3s ease-in;
  transform: scale(0.7);
}

.ClubOfficialsCard .frontLower h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 30px;
}
.ClubOfficialsCard .frontLower h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 22px;
}

.ClubOfficialsCard:hover {
  box-shadow: none;
  border: 2px solid #f9224b;
}

.ClubOfficialsCard:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.ClubOfficialsCard:hover::after {
  opacity: 1;
}

@media (max-width: 1024px) {
  .ClubOfficialsCard {
    margin: 10px;
    min-width: 260px;
    min-height: 280px;
  }
}

@media (max-width: 280px) {
  .ClubOfficialsCard {
    min-width: 75vw;
    min-height: 75vw;
    padding: 20px 12px;
    margin: 20px 0;
  }

  .ClubOfficialsCard .frontLower h1 {
    font-size: 28px;
  }
  .ClubOfficialsCard .frontLower h2 {
    font-size: 18px;
  }
}
