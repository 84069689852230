@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

.about,
.fewWords {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  min-height: 100vh;
  width: 100vw;
  background-color: black;
  justify-content: center;
  align-items: center;
}

.aboutSVG > img,
.fewWordsSVG > img,
.whatWeDoSVG > img {
  box-sizing: border-box;
  width: 45vw;
  padding: 50px;
}

.newsSVG > img {
  box-sizing: border-box;
  width: 45vw;
  padding-left: 50px;
}

.aboutContent,
.fewWordsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55vw;
  padding: 30px;
  background: url("../../SVGs//circle.svg");
  background-size: contain;
  background-blend-mode: multiply;
}

.whatWeDo {
  display: flex;
  flex-direction: row-reverse;
  min-height: 100vh;
  background-color: black;
  align-items: center;
  justify-content: center;
}

.whatWeDoContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55vw;
  background: url("../../SVGs//polygon.svg");
  background-size: cover;
  padding: 50px;
}

.newsNotification {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../SVGs/bg-news.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.newsContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  transform: translateX(-50vw);
}

.newsPhoneView {
  display: none;
}

#fewWordsHead,
#whatWeDoHead,
#aboutHead {
  font-family: "Teko", sans-serif;
  font-size: 4rem;
}

.fewWordsContent > p,
.aboutContent > p,
.whatWeDoContent > p {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  word-spacing: 6px;
  font-size: 1.1rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .about,
  .fewWords,
  .whatWeDo {
    flex-flow: wrap-reverse;
  }

  .aboutSVG > img,
  .fewWordsSVG > img,
  .whatWeDoSVG > img {
    width: 95vw;
    padding: 50px;
  }

  .newsSVG {
    visibility: hidden;
  }

  .newsContainer {
    visibility: hidden;
    display: none;
  }

  .newsPhoneView {
    display: inline;
  }

  .aboutContent,
  .fewWordsContent,
  .whatWeDoContent {
    width: 100vw;
  }
  #fewWordsHead,
  #whatWeDoHead,
  #aboutHead {
    font-family: "Teko", sans-serif;
    font-size: 3.4rem;
  }
}
