.Footer {
  display: flex;
  flex-direction: row;
  background-color: black;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
}

.Footer .FooterStrip {
  background-color: #f9224b;
  color: black;
  height: fit-content;
  overflow: scroll;
}

.Footer .FooterStrip h1 {
  font-family: "Teko", sans-serif;
  font-weight: 900;
  font-size: 3rem;
  margin-bottom: auto;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.Footer .FooterElementLeft {
  background-color: black;
  display: flex;
  flex-direction: column;
}

.Footer .FooterElementLeft .clubInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0px 0px 20px;
}

.Footer .FooterElementLeft .clubInfo .address {
  color: rgb(139, 137, 137);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: left;
}

.Footer .FooterElementLeft .clubInfo .name {
  font-family: "Teko", sans-serif;
  font-size: 52px;
  margin-top: 0px;
  text-align: left;
}

.Footer .FooterElementLeft .middle {
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 10px 0px;
}

.Footer .FooterElementLeft .middle .first {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  margin: 0px 50px 0px 20px;
  justify-content: flex-start;
}

.Footer .FooterElementLeft .middle .Links {
  text-decoration: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 22px;
  text-align: left;
  padding: 15px 10px;
}

.Footer .FooterElementLeft .middle .Links:hover {
  color: #f9224b;
}

.Footer .FooterElementMiddle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Footer .FooterElementMiddle div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  flex-flow: wrap;
  padding-left: 20px;
}

.Footer .FooterElementMiddle h1 {
  font-family: "Teko", sans-serif;
  font-size: 52px;
  margin-top: 0px;
  padding-left: 20px;
  width: 80%;
  text-align: left;
}

.Footer .FooterElementMiddle a {
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 22px;
  padding: 10px 30px;
  color: white;
  margin: 0px 50px 40px 0px;
  border: 2px solid #f9224b;
  text-decoration: none;
}
.Footer .FooterElementMiddle a:hover {
  box-shadow: 0px 0px 10px #f9224b, 0px 0px 40px#f9224b, 0px 0px 80px #f9224b;
}

.Footer .FooterElementRight .connectAt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #f9224b;
  height: 440px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: black;
  box-shadow: 0px 0px 10px #f9224b, 0px 0px 40px#f9224b, 0px 0px 80px #f9224b;
}

.Footer .FooterElementRight .connectAt .icons {
  width: 45px;
  height: 45px;
  margin: 0 10px;
  transition: 0.2s ease-in;
  cursor: pointer;
  transition: 0.2s ease;
}

.Footer .FooterElementRight .connectAt .icons:hover {
  transform: scale(0.9);
}

@media (max-width: 1024px) {
  .Footer {
    flex-flow: wrap;
  }

  .Footer .FooterElementLeft .middle {
    flex-flow: wrap;
  }

  .Footer .FooterElementRight .connectAt {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #f9224b;
    width: 90vw;
    height: fit-content;
    margin-left: 16px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px;
    background-color: black;
    box-shadow: 0px 0px 10px #f9224b, 0px 0px 40px#f9224b, 0px 0px 80px #f9224b;
  }
}

@media (max-width: 320px) {
  .big {
    width: 204px;
    height: 204px;
  }
  .Footer .FooterElementLeft .clubInfo .address {
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    text-align: left;
  }

  .Footer .FooterElementLeft .clubInfo .name {
    font-family: "Teko", sans-serif;
    font-size: 42px;
    margin-top: 0px;
    text-align: left;
  }

  .Footer .FooterElementLeft .middle .first {
    margin: 0px 100px 0px 20px;
  }

  .Footer .FooterElementRight .connectAt {
    margin-right: 0px;
  }

  .Footer .FooterElementRight .connectAt .icons {
    width: 30px;
    height: 30px;
  }

  .Footer .FooterElementMiddle p {
    padding: 6px 22px;
    margin: 0px 50px 40px 0px;
  }
}
