.Teams {
  background-color: black;
  min-height: 100vh;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

#teamName {
  font-family: "Teko", sans-serif;
  font-size: 62px;
  margin-left: 8vw;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 768px) {
  .Teams {
    background: url("../../SVGs/bearers.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    background-color: black;
    padding-top: 9em;
  }

  #teamName {
    font-family: "Teko", sans-serif;
    font-size: 58px;
    margin-left: 8vw;
    margin-top: 62px;
  }
}
