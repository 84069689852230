.project_cardparent{
  width: 300px;
  margin-left: 2vw;
  align-items: center;
  overflow: hidden;
  background-color: black;
  transition: 0.5s;
  animation: ease-in;
  /* box-shadow: 0px 0px 15px -5px; */
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.project_imagecontainer:hover{
  /* transform: scaleX(1.08)scaleY(1.1); */
  transform: scale(1.05);
  transition: 0.5s;
}
.project_imagecontainer img{
  height: 200px;
  width: 100%;
  padding: 5%;
  
  background-color: #f9224b;
}
.project_imagecontainer  :hover{
  height: 200px;
  width: 100%;
  padding: 0%;
  
  background-color: black;
}
.project_cardcontent{
  margin: 1rem;
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}
.project_cardtitle{
  margin-top: 0.5rem;
  text-transform: uppercase;
  color: orange;
  font-family: "Poppins", sans-serif;
  
}
.project_cardbody{
  overflow-y: unset;
  transform: none;
}
.project_cardbody :hover{
  transform: none;
  transition: none;
}
.project_button{
  border: none;
  border: 0ch;
  border-color: #f9224b;
  background-color: black;
  color: #f9224b;
  margin-bottom: 0.5rem;
  transition: 0.2s;
  padding: 0.5rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}
.project_button :hover{
  scale: (1.1);
  border: 0;
  background: black;
}
.project_button:focus{
  outline: none;
}
@media (max-width: 748px){
  .project_cardparent{
    width: 65vw;
    align-items: center;
    overflow: hidden;
    background-color: black;
    transition: 0.5s;
    animation: ease-in;
    /* box-shadow: 0px 0px 15px -5px; */
    border-radius: 20px;
    margin-bottom: 2rem;
  }
}
@media (max-width:480px) {
  .project_cardparent{
    width: 80vw;
    margin: auto;
    padding: auto;
    align-items: center;
    overflow: hidden;
    background-color: black;
    transition: 0.5s;
    animation: ease-in;
    /* box-shadow: 0px 0px 15px -5px; */
    border-radius: 20px;
    margin-bottom: 2rem;
  }
}