.project_heading{
    size: 3rem;
   
}
.project_back{
    background-color: black;
}
.grid{
    margin: 0 -5px;
    display: inline-grid;
   grid-template-columns: repeat(3,1fr);
   justify-content: center;
    
}

.project-grid{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    justify-content: center;
}

.cardColumn{
    float: left;
    width: 25%;
    padding: 0 10px;
}
.title {
  padding: 0%;
  margin: 0%;
  margin-top: 0%;
  font-family: "Teko", sans-serif;
  font-size: 62px;
  margin-left: 8%;
  margin-right: 8%;
  text-align: left;
}

@media (min-width: 1000px) {
.project-grid{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    justify-content: center;
    gap:2rem;
    margin-left: 5rem;
    margin-right: 5rem;
}
  }

  @media (max-width: 748px) {
    .grid{
        margin: 0 -5px;
        display: inline-grid;
       grid-template-columns: repeat(2,1fr);
        
    }
  }
  @media (max-width: 480px) {
    .grid{
        margin: 0 -5px;
        display: inline-grid;
       grid-template-columns: repeat(1,1fr);
       margin-bottom: 40px;
        
    }
    .cardColumn{
        float: left;
        width: 25%;
        padding: 0 10px;
        margin-top: 40px;
    }
  }