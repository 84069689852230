* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #000;
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}

::-webkit-scrollbar {
  display: none;
}
