.slider {
  height: 40rem;
  margin-right: 5rem;
  position: relative;
  width: 100%;
  display: grid;
  display: -ms-grid;
  text-align: left;
  overflow: hidden;
}
.slide-track {
  margin: auto;
  height: auto;
  animation: scroll 30s infinite linear;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
.slide {
  height: fit-content;
  width: 100%;
  align-items: center;
  padding: 5px;
  perspective: 100px;
}

.slide > p > a {
  text-decoration: none;
  color: #fff;
  font-family: "Poppins", sans-serif ;
}

.news {
  padding: 12px;
  width: 95%;
  background-color: black;
  border-radius: 0.8rem;
  transition: transform 0.5s ease-in;
  text-align: center;
  cursor: pointer;
  border: 2px solid transparent;
}
.news:hover {
  border: 2px solid #dc3545;
}

@media (max-width: 768px) {
  .slider {
    margin-right: 0;
    margin: auto;
    width: 90%;
  }
  .slide {
    margin: auto;
    width: 90%;
  }
  .news {
    width: 100%;
  }
}
