@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  background-color: transprent;
  left: auto;
}
.display-invisible{
 opacity: 0;
}

.headercontainer {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
}
.headercontainer > .medium {
  width: 192px;
  height: 144px;
  object-fit: contain;
}

.big {
  width: 320px;
  height: 150px;
  object-fit: contain;
  animation: bouncy 1s linear infinite;
}

@keyframes bouncy {
  0% {
    transform: translateY(0) scale(1);
    visibility: hidden;
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.name {
  font-family: "Teko", sans-serif;
  font-size: 62px;
  margin-top: -20px;
}

.hamburger {
  position: absolute;
  right: 1.5rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  cursor: pointer;
}

.hamburger > .fade {
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #f9224b;
  transition: 0.4s;
  transform: rotate(-135deg);
  opacity: 0;
}

.hamburger > .normal {
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #f9224b;
  transition: 0.4s;
}
.hamburger > .normal::before,
.hamburger > .normal::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #f9224b;
  transition: 0.4s;
}

.hamburger > .normal::before {
  top: -10px;
}

.hamburger > .normal::after {
  top: 10px;
}

.active_normal {
  transform: rotate(135deg);
}

.active_fade {
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #f9224b;
  transition: 0.4s;
  transform: rotate(-135deg);
  opacity: 1;
}

.hamburger:hover .active_normal {
  transform: rotate(225deg);
}
.hamburger:hover .active_fade {
  transform: rotate(-45deg);
}

.active_normal::after,
.active_normal::before {
  opacity: 0;
}

.menu_left {
  position: absolute;
  left: 0;
  top: -100vh;
  width: 60%;
  background-color: #f9224b;
  height: 100vh;
  transform: translateY(-100vh);
  transition: 0.8s;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.menu_left > a {
  position: relative;
  text-decoration: none;
  font-family: "Teko", sans-serif;
  font-size: 62px;
  margin-left: 100px;
  color: white;
  padding-bottom: 50%;
  transition: 0.8s;
  opacity: 0;
}

.menu_left > a::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  left: 0;
  bottom: 1rem;
  border-radius: 1.5px;
  background-color: black;
  transition: 0.6s;
  opacity: 0;
}

.menu_left > a > span {
  font-size: 26px;
  margin-right: 10px;
  opacity: 0;
  transition: 0.4s ease-in;
}

.menu_left > a:hover {
  margin-left: 140px;
}
.menu_left > a:hover span {
  opacity: 1;
}

.menu_left > a:hover::after {
  width: 100%;
  opacity: 1;
}

.menu_right {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  background-color: #000;
  height: 100vh;
  transform: translateX(100%);
  transition: 0.8s ease-in-out;
  opacity: 0.3;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 100px;
}

.menu_left_active {
  transform: translateY(0vh);
  opacity: 1;
  top: 0vh;
  visibility: visible;
}

.menu_right_active {
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
}
.menu_left_active > a {
  padding-bottom: 0;
  opacity: 1;
}

.menu_right .clubInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.menu_right .clubInfo .address {
  color: rgb(139, 137, 137);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: left;
}

.menu_right .connectAt {
  padding: 20px;
}

.menu_right .connectAt .icons {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.menu_right .connectAt .icons:hover {
  transform: translateY(-6px);
}

@media (max-width: 1024px) {
  .menu_left {
    width: 50%;
  }

  .menu_left > a {
    margin-left: 0px;
  }

  .menu_left > a:hover {
    margin-left: 40px;
  }

  .menu_right {
    width: 50%;
    padding: 35px;
  }
  .name {
    font-family: "Teko", sans-serif;
    font-size: 50px;
    margin-top: 5px;
  }

  .menu_right .clubInfo .address {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .menu_right {
    display: none;
  }
  .menu_left {
    width: 100%;
  }

  .menu_left > a {
    margin-left: 0;
  }

  .hamburger {
    background-color: black;
  }

  .menu_left > a:hover {
    margin-left: 50px;
  }
}

@media (max-width: 375px) {
  .menu_left {
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
  }

  .hamburger {
    width: 50px;
    height: 50px;
  }

  .headercontainer > .medium {
    width: 152px;
    height: 104px;
    object-fit: contain;
  }
}

@media (max-width: 280px) {
  .big {
    width: 144px;
    height: 144px;
    object-fit: contain;
  }
}
