.share_parent {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 40px;
  height: 200px;
  background-color: black;
  border-radius: 5px 0 0 5px;
  box-shadow: 0px 10px 100px 2px rgba(46, 61, 98, 0.3);
  -webkit-box-box-shadow: 0px 10px 100px 2px rgba(46, 61, 98, 0.3);
  margin: 0 !important;
  padding: 0 !important;
  z-index: 105;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.share_parent > .share_me {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
}

.share_parent > .share_me button.react-share__ShareButton {
  outline: none;
}

.share_parent > .share_me button.react-share__ShareButton:hover {
  background-color: white !important;
}

.share_parent > .share_me .share_social_icon {
  color: #f9224b;
  max-width: 40px;
  max-height: 40px;
  font-family: "FontAwesome";
  font-size: 30px;
  margin: 5px !important;
  padding: 0 !important;
}

.share_parent.dont_show {
  -webkit-transform: scale(0);
  transform: scale(0);
}
