@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,600,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Glory&display=swap");

.EventsMainPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: url("../../SVGs/circle.svg");
  background-size: cover;
  padding: 0rem 3.5rem;
}

.quotesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.quotesContainer .aiSVG {
  width: 450px;
  animation: zoomInOut 1s linear infinite;
}

@keyframes zoomInOut {
  0% {
    transform: translateY(50px);
    visibility: hidden;
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(50px);
  }
}

.EventsMainPage h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-size: 6em;
  width: 9em;
  line-height: 0.9em;
  text-align: left;
  padding-top: 1.7em;
}

.quote-text {
  background: #ca4246;
  background-color: #ca4246;
  background: conic-gradient(
    #f9224b 16.666%,
    #e16541 16.666%,
    #e16541 33.333%,
    #f18f43 33.333%,
    #f18f43 50%,
    #8b9862 50%,
    #8b9862 66.666%,
    #a7489b 66.666%,
    #f9224b 73.333%,
    #f9224b 73.333%
  );
  background-size: 57%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: quote-text-animation-rev 0.5s ease forwards;
}

.quote-text:hover {
  animation: quote-text-animation 0.5s ease forwards;
}

@keyframes quote-text-animation {
  0% {
    background-size: 57%;
    background-position: 0 0;
  }
  20% {
    background-size: 57%;
    background-position: 0 1em;
  }
  100% {
    background-size: 300%;
    background-position: -9em 1em;
  }
}

@keyframes quote-text-animation-rev {
  0% {
    background-size: 300%;
    background-position: -9em 1em;
  }
  20% {
    background-size: 57%;
    background-position: 0 1em;
  }
  100% {
    background-size: 57%;
    background-position: 0 0;
  }
}

blockquote.quote {
  position: relative;
  text-align: left;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  width: 80%;
  color: white;
  margin-top: 1rem;
}
blockquote.EN {
  background: linear-gradient(to right, #f9224b 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #f9224b 4px, transparent 4px) 100% 0,
    linear-gradient(to bottom, #f9224b 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #f9224b 4px, transparent 4px) 0 100%;
  background-repeat: no-repeat;
  background-size: 14px 14px;
}

blockquote.DE {
  background: linear-gradient(to right, #f9224b 4px, transparent 4px) 0% 0%,
    linear-gradient(to bottom, #f9224b 4px, transparent 4px) 0% 0%,
    linear-gradient(to left, #f9224b 4px, transparent 4px) 100% 100%,
    linear-gradient(to top, #f9224b 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 14px 14px;
}

blockquote.quote:before,
blockquote.quote:after {
  font-family: fontAwesome;
  position: absolute;
  color: #f9224b;
  font-size: 24px;
}

blockquote.EN:before {
  content: "\f10d";
  top: -12px;
  margin-right: -20px;
  right: 100%;
}
blockquote.EN:after {
  content: "\f10e";
  margin-left: -20px;
  left: 100%;
  top: auto;
  bottom: -20px;
}

@media (max-width: 1024px) {
  blockquote.quote {
    font-size: 1.4rem;
    width: 85vw;
  }
  .EventsMainPage h1 {
    font-family: "Work Sans", sans-serif;
    font-weight: 800;
    font-size: 4.7em;
    width: 85vw;
    line-height: 0.9em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.7em;
  }

  .EventsMainPage {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .aiSVG {
    display: none;
  }
}

@media (max-width: 768px) {
  blockquote.quote {
    font-size: 0.8rem;
    width: 85vw;
  }
  .EventsMainPage h1 {
    font-family: "Work Sans", sans-serif;
    font-weight: 800;
    font-size: 2.7em;
    width: 90vw;
    line-height: 0.9em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .EventsMainPage {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0rem 1.5rem;
  }
}

@media (max-width: 340px) {
  blockquote.quote {
    font-size: 0.6rem;
    width: 85vw;
  }
  .EventsMainPage h1 {
    font-family: "Work Sans", sans-serif;
    font-weight: 800;
    font-size: 2.3em;
    width: 85vw;
    line-height: 0.9em;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
  }

  .EventsMainPage {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
