.blog_parent {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#000000e8),
      to(#000000ef)
    ),
    url("https://images.unsplash.com/photo-1458682625221-3a45f8a844c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80");
  background-image: linear-gradient(0deg, #000000e8, #000000ef),
    url("https://images.unsplash.com/photo-1458682625221-3a45f8a844c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80");
  background-size: 100% 100%;
}

.blog_parent .blog_title {
  margin: 20px 0 40px;
  color: #fd6e6e;
  font-family: "Teko", sans-serif;
  font-size: 3.5rem;
}

.blog_parent .blog_image {
  height: 40vh;
  margin: auto ;
  justify-content: center;
  overflow: hidden;
}

.blog_parent .blog_image img {
  width: 80vw;
  justify-content: center;
}

.blog_parent .blog_content,
.blog_parent .blog_credits {
  width: 70%;
  margin:15%;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  word-spacing: 6px;
  font-size: 1.1rem;
  text-align: left;
}

.blog_parent .blog_content p,
.blog_parent .blog_credits p {
  text-indent: 20px;
}

.blog_parent .blog_content p ol,
.blog_parent .blog_content p ul,
.blog_parent .blog_credits p ol,
.blog_parent .blog_credits p ul {
  padding-left: 30px;
}

.blog_parent .blog_content p ol li,
.blog_parent .blog_content p ul li,
.blog_parent .blog_credits p ol li,
.blog_parent .blog_credits p ul li {
  text-align: left;
  padding: 5px 2.5px;
}

.blog_parent .blog_credits {
  color: #fd6e6e;
  text-align: center;
  font-style: italic;
}

.blurbg {
  filter: blur(4px);
}

.toggle_sharex_btn {
  position: absolute;
  right: 40px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  color: #ffaeae;
  font-size: 35px;
  cursor: pointer;
  text-shadow: 5px 5px 15px rgb(0, 0, 0), 10px 10px 20px #333;
  transition: 0.8s;
  z-index: 30;
}
.back-btn{
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 4rem;
  color: #ff3939;
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
}
.back-btn:hover{
  color: #ff6565;
}

@media screen and (max-width:768px) {
  .blog_parent{
    padding-top: 8rem;
  }
 .blog_parent .blog_content,
.blog_parent .blog_credits {
  width: 80%;
  margin:10%;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  word-spacing: 6px;
  font-size: 1.1rem;
  text-align: left;
}
.blog_parent .blog_image {
  max-height: 40vh;
  margin: auto ;
  justify-content: center;
  overflow: hidden;
}
}