.loader-container {
    background-color: #000000;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loader {
    margin: auto;
}

.loader-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 5rem 0;
}

.loader {
    margin-top: 2rem;
    border: 10px solid #afafaf;
    border-radius: 50%;
    border-top: 10px solid #e72145;
    width: 5rem;
    height: 5rem;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}