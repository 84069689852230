.events-main > h1 {
  padding: 10px 20px;
  border-left: 3px solid #e72145;
  margin: 15px;
  margin-left: 40px;
  font-family: "Teko", sans-serif;
  font-weight: 800;
  font-size: 3.5em;
  letter-spacing: 2px;
  text-align: left;
}
.event-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  color: black;
  background-color: black;
  transition: 0.15s;
}
.event__card-parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  color: black;
}

.event__card-parent-overlay .card .content {
  display: none;
  transition: 0.5s ease-in-out;
}
.event__card-parent .card {
  position: relative;
  border-radius: 5px;
  margin: 30px 10px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  max-height: 293.641px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  background-color: rgb(31, 30, 30);
  
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  border-radius: 12px;
}
.event__card-parent .card:hover {
  display: flex;
  max-height: 800px;
  transition: max-height 0.5s ease-in-out;
  box-shadow: 5px 5px 10px rgba(17, 25, 40, 0.863),
    -5px -5px 10px rgba(17, 25, 40, 0.514);
}
.event_heading{
  color: #f9224b;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.event__card-parent .card > img:hover .card {
  display: flex;
  max-height: 0px;
  transition: 0.3s ease-in-out;
  
}
.event__card-parent .card .img8x_normal {
  position: relative;
  top: -50px;
  width: 100%;
  z-index: 1;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  object-fit: contain;
}

.event__card-parent .card .img8x_normal > img {
  border-radius: 5px;
  width: 90%;
  height: 293.641px;
  transition: 0.3s;
  position: relative;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.event__card-parent .card .content {
  position: relative;
  margin-top: -100px;
  visibility: hidden;
  padding: 10px 15px;
  color: white;
  opacity: 0;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}
.event-card__details {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding: 0px 30px;
  padding-right: 20px;
  font-family: "Poppins", sans-serif;
}
.event-card__details-paragraph {
  white-space: pre-wrap;
}
.event-card__details-reward {
  margin: 10px 0px;
}
.event-card__details-reward-list {
  margin-left: 25px;
}
.know-more-event {
  border-radius: 0.6rem;
  background-color: black;
  color: #00ffdd;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  margin: 5px;
  border: none;
}
.know-more-event__button-div {
  align-self: center;
}
.know-more-event:hover {
  background-color: #f9224b;
}
.event__card-parent .card:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;
}

@media (max-width: 768px) {
  .event-list {
    grid-template-columns: repeat(1, 1fr);
    margin-top: -26px;
  }
}
@media (max-width: 450px) {
  .event-card__details {
    padding: 0px 10px;
  }
  .event__card-parent .card {
    max-height: 120px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .event__card-parent .card .img8x_normal {
    top: -39px;
  }
  .event__card-parent .card .img8x_normal > img {
    height: 120px;
  }
}
